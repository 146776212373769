import { isBlank } from '@/utils/strings';
import { API } from '@/api';
import { generateUUID } from '@/utils/uuid';
import {
  getFileType,
  isScan,
  isViewableNonScan,
  uploadFile,
} from '@/utils/files';
import { UPLOAD_STATUSES, type UploadStatus } from '@/constants/uploadStatuses';
import type { Dictionary } from '@/types';
import { LoadedFile } from '@/providers/LoadedFilesProvider';
import {
  type DecoratedSequenceStepFile,
  FILE_TYPES,
  type FileType,
  type UndecoratedFile,
  type UndecoratedFileVersion,
} from '@witmetrics/api-client';
import { loadScan } from '@/utils/scans';
import { sortNumerical } from '@/utils/arrays';

const { IDLE, IN_PROGRESS, DONE, ERROR } = UPLOAD_STATUSES;

export const classes = {
  dialog:
    'w-[98vw] max-w-[800px] min-w-[400px] min-h-[450px] max-h-[750px] h-[90vh] flex flex-col',
  body: 'grow pt-0 min-h-0 mb-6',
  actions: 'pt-2',
  titleWrapper: 'flex items-center justify-between',
  divider: 'my-4',
  filesWrapper: 'grow flex flex-col h-full overflow-y-auto pr-4 pt-2',
  addIcon: 'mr-2 text-2xl',
  addButton:
    'ml-4 bg-purple-2 text-white cursor-pointer pl-2 pr-6 py-2 rounded-xl flex items-center justify-center hover:shadow-lg transition-all',
  iconButton: 'text-grey-6 hover:text-grey-7 transition-colors cursor-pointer',
  emptyLabel: 'text-grey-6 text-xl text-center my-8',
};

export type SelectedFile = {
  id?: number; // For existing files
  uuid: string; // To keep track of all files
  uploadStatus: UploadStatus;
  position?: number;
  fileType?: FileType;
  file: File;
};

export type GroupedFiles = Dictionary<SelectedFile[]>;

export function groupByPosition(
  groupedFiles: GroupedFiles,
  selectedFiles: SelectedFile[]
): GroupedFiles {
  let result = { ...groupedFiles };
  selectedFiles.forEach((sf) => {
    const position = sf.position === undefined ? 'UNSORTED' : `${sf.position}`;
    if (!result[position]) result[position] = [];
    result[position].push(sf);
  });
  return result;
}

export function addStep(position: number, selectedFiles: GroupedFiles) {
  const files = { ...selectedFiles };
  // NOTE: Reversing the keys to avoid overwriting existing keys
  Object.keys(files)
    .reverse()
    .forEach((key) => {
      if (parseInt(key) >= position) {
        files[parseInt(key) + 1] = files[key].map((sf) => ({
          ...sf,
          position: parseInt(key) + 1,
        }));
        delete files[key];
      }
    });
  files[position] = [];
  return files;
}

export function removeStep(position: string, selectedFiles: GroupedFiles) {
  const files = { ...selectedFiles };
  if (position === 'UNSORTED') {
    delete files[position];
    return files;
  }
  const pos = parseInt(position);
  Object.keys(files).forEach((key) => {
    if (parseInt(key) === pos) delete files[key];
    if (parseInt(key) > pos) {
      files[parseInt(key) - 1] = files[key].map((sf) => ({
        ...sf,
        position: parseInt(key) - 1,
      }));
      delete files[key];
    }
  });
  return files;
}

export function validateFields(name: string) {
  let fields: string[] = [];
  if (isBlank(name)) fields.push('name');
  return fields;
}

export function isDisabled(selectedFiles: GroupedFiles) {
  return (
    Object.values(selectedFiles).flat().length === 0 ||
    selectedFiles['UNSORTED']?.length > 0
  );
}

export function buildSelectedFile(file: File): SelectedFile {
  const stepMatch = file.name.match(/step_(\d+)/);
  return {
    uuid: generateUUID(),
    uploadStatus: IDLE,
    position: stepMatch ? parseInt(stepMatch[1]) : undefined,
    fileType: getFileType(file.name),
    file,
  };
}

type NewSequenceData = {
  name: string;
  description: string;
  practiceID: number;
  fileCollectionID: number;
  selectedFiles: GroupedFiles;
};

export async function createNewSequence(
  { name, description, fileCollectionID, selectedFiles }: NewSequenceData,
  onUploadProgress: (selectedFile: SelectedFile) => void
) {
  // Create a new sequence
  const sequence = await API.Sequences.createNewSequence({
    name,
    description,
  });
  // Add it to the file collection & create all the sequence steps
  const [fileCollectionSequence, ...sequenceSteps] = await Promise.all([
    addSequenceToCollection(sequence.id, fileCollectionID),
    ...Object.keys(selectedFiles).map((position) =>
      createSequenceStep(sequence.id, parseInt(position))
    ),
  ]);
  // Upload all files/fileVersions & add them to their respective sequence step
  const uploads = await Promise.all(
    sequenceSteps.map((step) =>
      uploadFilesToStep(
        step.id,
        sequence.practiceID,
        selectedFiles[step.position],
        onUploadProgress
      )
    )
  );
  return {
    sequence,
    fileCollectionSequence,
    sequenceSteps,
    ...parseUploadArray(uploads),
  };
}

function addSequenceToCollection(sequenceID: number, collectionID: number) {
  return API.FileCollections.addFileCollectionSequence(
    collectionID,
    sequenceID
  );
}

function createSequenceStep(sequenceID: number, position: number) {
  return API.Sequences.addSequenceStep(sequenceID, {
    position,
    name: null,
    description: null,
  });
}

async function uploadFilesToStep(
  stepID: number,
  practiceID: number,
  selectedFiles: SelectedFile[],
  onUploadProgress: (selectedFile: SelectedFile) => void
) {
  return await Promise.all(
    selectedFiles.map((sf) =>
      uploadFileToStep(stepID, practiceID, sf, onUploadProgress)
    )
  );
}

async function uploadFileToStep(
  stepID: number,
  practiceID: number,
  selectedFile: SelectedFile,
  onUploadProgress: (selectedFile: SelectedFile) => void
) {
  try {
    onUploadProgress({ ...selectedFile, uploadStatus: IN_PROGRESS });
    const { file, fileVersion } = await uploadFile(
      selectedFile.file,
      practiceID,
      selectedFile.fileType ? FILE_TYPES[selectedFile.fileType].id : undefined
    );
    const sequenceStepFile = await API.Sequences.addFileToSequenceStep(
      stepID,
      file.id
    );
    // Load file locally, no need to download right after uploading
    let loadedFile: LoadedFile | undefined;
    const buffer = await selectedFile.file.arrayBuffer();
    if (isScan(fileVersion.name)) {
      loadedFile = loadScan(fileVersion.name, buffer)!;
    } else if (isViewableNonScan(fileVersion.name)) {
      loadedFile = URL.createObjectURL(new Blob([buffer]));
    }
    onUploadProgress({ ...selectedFile, uploadStatus: DONE });
    return { file, fileVersion, sequenceStepFile, loadedFile };
  } catch (err) {
    onUploadProgress({ ...selectedFile, uploadStatus: ERROR });
    throw err;
  }
}

function parseUploadArray(
  uploads: Awaited<ReturnType<typeof uploadFilesToStep>>[]
) {
  let files: UndecoratedFile[] = [];
  let fileVersions: UndecoratedFileVersion[] = [];
  let sequenceStepFiles: DecoratedSequenceStepFile[] = [];
  let loadedFiles: { [key: string]: LoadedFile } = {};
  uploads.flat().forEach((u) => {
    files.push(u.file);
    fileVersions.push(u.fileVersion);
    sequenceStepFiles.push(u.sequenceStepFile);
    if (u.loadedFile) loadedFiles[u.file.id] = u.loadedFile;
  });
  return { files, fileVersions, sequenceStepFiles, loadedFiles };
}

export function buildSequenceName(name: string, selectedFiles: GroupedFiles) {
  if (!isBlank(name)) return name;
  const steps = sortNumerical(
    Object.keys(selectedFiles)
      .filter((s) => s !== 'UNSORTED')
      .map((s) => parseInt(s))
  );
  return `Steps ${steps[0]} - ${steps[steps.length - 1]}`;
}
